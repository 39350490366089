
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600|Oswald|Lobster|Crafty+Girls|Pacifico|Satisfy|Gloria+Hallelujah|Bangers|Audiowide|Sacramento);
// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome";

.StripeElement {
  background-color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.project-wrapper {
  position: relative;
}

.project-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%)
}

.project-footer{
  transition: .5s ease;
  opacity: 0;
  position:absolute;
  bottom: -10px;
  right: 15px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 100;
}

.project-wrapper:hover .project-image {
  opacity: 0.3;

}

.project-wrapper:hover .middle {
  opacity: 1;
  cursor: pointer;
}

.project-wrapper:hover .project-footer {
  opacity: 1;
  cursor: pointer;
}

.text {
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}


@import "designer.scss";