#main-nav{
  margin-bottom:0;
}

#libraryViewerWrapper{
  position:fixed;
  top: 50px;
  left:0px;
  width: 270px;
  height:95%;
  overflow:hidden;
  z-index:99999;
}

#libraryViewerWrapper .libraryViewerInner{
  position:fixed;
  top: 50px;
  width:250px;
  height:100%;
  background-color:#fff;

  left: -250px;
  background-color: #232323;
  text-align:center;
  margin:0;
}

#libraryViewerWrapper .displayButton{
  background-color: #232323;
  color:#fff;
  width: 40px;
  height: 40px;
  cursor:pointer;
  text-align:center;
  position:absolute;
  right:-40px;
  padding:5px;
  top:0;
  font-size: 20px;
}

#asset_library{
  list-style:none;
  margin:0;
  padding:0;
}

#asset_library li{
  list-style: none;
}

.panel-group .panel{ border-radius:0;}
.panel-group .panel{
  background-color: transparent;
  border-color:transparent;
}
.panel-default > .panel-heading{
  background-color: transparent;
  border-color:transparent;
}
.panel-title > a{
  font-weight:bold;
  color:#fff;
  display:block;
  border-color:transparent;
}

.fixed-panel {
  min-height: 100%;
  max-height: 100%;
  overflow-y: scroll;
}


#asset_library li img{
  cursor: pointer;
  border-width:3px;
}

#asset_library li img:hover{
  border-color: #67b168;
}

.project_type{
  padding-top: 10px; padding-bottom:10px;
  cursor: pointer;
  text-align:center;
}
.asset_type{
  padding-top: 10px;
  padding-bottom:10px;
  cursor: pointer;
  text-align:center;
}

// Canvas Styles
canvas{
  -webkit-transform : scale(1);
  -webkit-transform-origin : 0 0;
}
.canvas-container{
  -webkit-transform : scale(0.45);
  -webkit-transform-origin : 0 0;
}

.canvas-container.flyer{
  -webkit-transform : scale(1);
  -webkit-transform-origin : 0 0;
}


.jcolor-picker{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #383838;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
}

.layer-item{
  cursor: pointer;
}

#project-layer_list li.layer-item.selected{
  background-color: #383838;
  color:#fff;
}



/* Icon pulse */
.fa-pulse {
  display: inline-block;
  -moz-animation: pulse 1.5s infinite linear;
  -o-animation: pulse 1.5s infinite linear;
  -webkit-animation: pulse 1.5s infinite linear;
  animation: pulse 1.5s infinite linear;
}

#project_name{
  margin-top:14px;
  border-bottom:solid 1px #ccc;
  border-top:none;
  border-right:none;
  outline: none;
  border-left:none;
  box-shadow:none;
  border-radius:0;
  transition: border-bottom 0.1s linear;
}
#project_name:focus{
  border-bottom: solid 5px #2ab27b;
}

#project_name.warning:focus{
  border-bottom:solid 5px #ee3535;
}



.modal-confirm {
  color: #636363;
  width: 400px;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}
.modal-confirm .modal-footer a {
  color: #999;
}
.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}


.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}

.icon-box.success, icon-box.success i, .modal-confirm .icon-box.success i{
  color:#82ce34;
  border-color:#82ce34;
}
.modal-body p strong{ font-size: 16px;}

.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
  outline: none !important;
}
.modal-confirm .btn-info {
  background: #c1c1c1;
}
.modal-confirm .btn-info:hover, .modal-confirm .btn-info:focus {
  background: #a8a8a8;
}
.modal-confirm .btn-danger {
  background: #f15e5e;
}
.modal-confirm .btn-danger:hover, .modal-confirm .btn-danger:focus {
  background: #ee3535;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.disableControl{
  cursor: pointer;
  color: lightgray;
}

/*
@-webkit-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@-ms-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
*/


@-webkit-keyframes pulse {
  0% { border-color: rgba(255,0,0, 0); }
  50% { border-color: rgba(255,0,0, 0.5); }
  100% { border-color: rgba(255,0,0, 1); }
}
@-moz-keyframes pulse {
  0% { border-color: rgba(255,0,0, 0); }
  50% { border-color: rgba(255,0,0, 0.5); }
  100% { border-color: rgba(255,0,0, 1); }
}

@-o-keyframes pulse {
  0% { border-color: rgba(255,0,0, 0); }
  50% { border-color: rgba(255,0,0, 0.5); }
  100% { border-color: rgba(255,0,0, 1); }
}

@-ms-keyframes pulse {
  0% { border-color: rgba(255,0,0, 0); }
  50% { border-color: rgba(255,0,0, 0.5); }
  100% { border-color: rgba(255,0,0, 1); }
}

@keyframes pulse {
  0% { border-color: rgba(255,0,0, 0); }
  50% { border-color: rgba(255,0,0, 0.5); }
  100% { border-color: rgba(255,0,0, 1); }
}
